import { default as about_45passckNgC3zNkqMeta } from "/codebuild/output/src2077296473/src/src/pages/about-pass.vue?macro=true";
import { default as agreementc5l51Yk6i1Meta } from "/codebuild/output/src2077296473/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45alias7KS2L8RL6vMeta } from "/codebuild/output/src2077296473/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirmPauYe8eiS7Meta } from "/codebuild/output/src2077296473/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45aliasU3faV7GqOCMeta } from "/codebuild/output/src2077296473/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as edit7RtFicyqnvMeta } from "/codebuild/output/src2077296473/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as indexH3qO4XWW7ZMeta } from "/codebuild/output/src2077296473/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirmjYG9fUCDefMeta } from "/codebuild/output/src2077296473/src/src/pages/card/create/confirm.vue?macro=true";
import { default as indexiPjnH2Lbc4Meta } from "/codebuild/output/src2077296473/src/src/pages/card/create/index.vue?macro=true";
import { default as indexNc1juBardsMeta } from "/codebuild/output/src2077296473/src/src/pages/card/index.vue?macro=true";
import { default as complete6hHAiZ6MTWMeta } from "/codebuild/output/src2077296473/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirm3eKXJ1kK4uMeta } from "/codebuild/output/src2077296473/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as registernwKGSAyB01Meta } from "/codebuild/output/src2077296473/src/src/pages/contact-form/register.vue?macro=true";
import { default as contactKhV3kVLSZgMeta } from "/codebuild/output/src2077296473/src/src/pages/contact.vue?macro=true";
import { default as _91id_93sJbS36geqJMeta } from "/codebuild/output/src2077296473/src/src/pages/coupon/[id].vue?macro=true";
import { default as indexroGUPzfyZZMeta } from "/codebuild/output/src2077296473/src/src/pages/coupon/index.vue?macro=true";
import { default as faqBPyKNtJz8CMeta } from "/codebuild/output/src2077296473/src/src/pages/faq.vue?macro=true";
import { default as indexZqOj7tELLsMeta } from "/codebuild/output/src2077296473/src/src/pages/history/index.vue?macro=true";
import { default as _91id_93EPHpdU7617Meta } from "/codebuild/output/src2077296473/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as index4dfz1ykOodMeta } from "/codebuild/output/src2077296473/src/src/pages/index.vue?macro=true";
import { default as maintenance9aXRw2chmVMeta } from "/codebuild/output/src2077296473/src/src/pages/maintenance.vue?macro=true";
import { default as completeQGkSskElw3Meta } from "/codebuild/output/src2077296473/src/src/pages/membership/complete.vue?macro=true";
import { default as confirmU2t987VH8QMeta } from "/codebuild/output/src2077296473/src/src/pages/membership/confirm.vue?macro=true";
import { default as register84V5FfZ3ZqMeta } from "/codebuild/output/src2077296473/src/src/pages/membership/register.vue?macro=true";
import { default as confirm_45codeq5U3Bhlo2qMeta } from "/codebuild/output/src2077296473/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as confirmVuEpsx6sVlMeta } from "/codebuild/output/src2077296473/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as registerFq7xX19JHTMeta } from "/codebuild/output/src2077296473/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as menuneNpR2LZ3sMeta } from "/codebuild/output/src2077296473/src/src/pages/menu.vue?macro=true";
import { default as indexaI6KRGAiSXMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/index.vue?macro=true";
import { default as indexNNjl2SFwTaMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as sample5iegFaU48zMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as completewHyIDr1eOCMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45codeyXFqgbVhl7Meta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as indexv2LyUKPN8RMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as indexMyNxA5bRHQMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as completeRj58A0u5eaMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirmXfVfcaDUIbMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as index0Ogr1AwaLEMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as updategtKzngNdHTMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as confirmRWLAy1nt2CMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
import { default as indexj1D4FpeENRMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as updateUbHOBle302Meta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as completeIonEPXiIVcMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as updateXCJ2gXOtvcMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as indexS5bLDvUUSAMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_93ZACF5DctOsMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as completeTGlMMatkMCMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirmwvbTjPaoOVMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as completeHaY6yqMBHcMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirm3PDUQMyvCnMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consentgpVaqHXvAJMeta } from "/codebuild/output/src2077296473/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_93gnp17EGjELMeta } from "/codebuild/output/src2077296473/src/src/pages/notice/[id].vue?macro=true";
import { default as indexMt7LNefoaNMeta } from "/codebuild/output/src2077296473/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93ujNYJpmwgHMeta } from "/codebuild/output/src2077296473/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementz1CmqURmVNMeta } from "/codebuild/output/src2077296473/src/src/pages/pass-agreement.vue?macro=true";
import { default as completeKFmOtjHTGyMeta } from "/codebuild/output/src2077296473/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codeL3XGkdzCQdMeta } from "/codebuild/output/src2077296473/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45passworde3cQxRnlizMeta } from "/codebuild/output/src2077296473/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45emailHy14A1ynYXMeta } from "/codebuild/output/src2077296473/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codePwuNYjdgTIMeta } from "/codebuild/output/src2077296473/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as indexTIhMspnltlMeta } from "/codebuild/output/src2077296473/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45code1Efnd800V7Meta } from "/codebuild/output/src2077296473/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as indexb5JqGCYcYuMeta } from "/codebuild/output/src2077296473/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorialJAzkyE3V2bMeta } from "/codebuild/output/src2077296473/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/about-pass.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/agreement.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45alias7KS2L8RL6vMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmPauYe8eiS7Meta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45aliasU3faV7GqOCMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: edit7RtFicyqnvMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirmjYG9fUCDefMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indexiPjnH2Lbc4Meta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/card/create/index.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/card/index.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: complete6hHAiZ6MTWMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirm3eKXJ1kK4uMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: registernwKGSAyB01Meta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/contact-form/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/contact.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/coupon/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: indexroGUPzfyZZMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/coupon/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/faq.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexZqOj7tELLsMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/history/index.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: index4dfz1ykOodMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/maintenance.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completeQGkSskElw3Meta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirmU2t987VH8QMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/membership/confirm.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: register84V5FfZ3ZqMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/membership/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45codeq5U3Bhlo2qMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirmVuEpsx6sVlMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: registerFq7xX19JHTMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menuneNpR2LZ3sMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/menu.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexaI6KRGAiSXMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexNNjl2SFwTaMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: sample5iegFaU48zMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexS5bLDvUUSAMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: completeTGlMMatkMCMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirmwvbTjPaoOVMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completeHaY6yqMBHcMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirm3PDUQMyvCnMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consentgpVaqHXvAJMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/notice/[id].vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexMt7LNefoaNMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93ujNYJpmwgHMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/parking/[id].vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/pass-agreement.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: completeKFmOtjHTGyMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codeL3XGkdzCQdMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45passworde3cQxRnlizMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45emailHy14A1ynYXMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codePwuNYjdgTIMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexTIhMspnltlMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45code1Efnd800V7Meta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexb5JqGCYcYuMeta || {},
    component: () => import("/codebuild/output/src2077296473/src/src/pages/sign-up/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src2077296473/src/src/pages/tutorial.vue")
  }
]